import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currencyList: [ 'USD', 'EUR', 'CAD' ],
    currency: 'USD',
    timeframeList: [ 5, 10, 40 ],
    timeframe: [ 5, 10, 40 ],
    displayList: [ 'Spread', 'Yield', '3MLSpread' ],
    display: 'Spread',
    contentType: [ 'FIX', 'FRN' ],
    search: '',
  },
  mutations: {
    currencyChange (state, newname) {
      state.currency = newname
    },
    timeFrameChange (state, newname) {
      state.timeframe = newname;
    },
    displayChange (state, newname) {
      state.display = newname
    },
    searchChange (state, newname) {
      state.search = newname
    },
  },
  getters: {
  },
  actions: {
  },
  modules: {
  }
})
