  
export default {
  data() {
    return {
      counter: 0,
      timeFrame: this.$store.state.timeframe,
      // Added for vue2
      store: this.$store.state,
    }
  },
  methods: {
   curChange(event) {
        var data = event.target.value;
        this.$store.commit("currencyChange", data)
    },
   timeFrameChange(event) {
        var data = this.timeFrame;
        data.sort((a, b) => a - b );
        this.$store.commit("timeFrameChange", data)
    },
   displayChange(event) {
        var data = event.target.value;
        this.$store.commit("displayChange", data)
    },
   searchChange(event) {
        var data = event.target.value;
        this.$store.commit("searchChange", data)
    },
 }
}
