
import compDataGet from './../data.json';
import moment from 'moment';
import Vue from 'vue';

export default {
  data() {
    return {
      compData: compDataGet.Items,
      displayGroup: this.$store.state.displayList,
      displayCurrent: this.$store.state.display,
      timeFrameList: this.$store.state.timeframeList,
      contentType: this.$store.state.contentType,
      valueTFObject: [],
      toggleShow: [],
      sortBy: [],
      minimalValue: {5: 0,10: 0,40: 0}, // ContentType 'FIX' only
      minimalValueFIX: {5: 0,10: 0,40: 0},
      averageFooter: [],
      averageFooterFinal: [],
      // Added for Vue2
      store: this.$store.state,
    }
  },
  methods:{
    toggleShowSet(id) {
      Vue.set(this.toggleShow,id,!this.toggleShow[id]);
    },
    toggleShowGet(id) {
      return this.toggleShow[id];
    },
    format_date(value){
       if (value) {
         return moment(String(value)).format('DD-MMM-YY')
        }
    },
    getTimeFrame(code) {
      // check which TF are selected
      var result = this.$store.state.timeframe.filter(function(data){ return data == code});
      if (result == code) {
        return true;
      } else {
        return false;
      }
    },
    showRowItemBP(item) {
      // Is it Yield or else
      if (item!='' && item!=null) {
          return true;
      } else {
        return false;
      }
    },

    // Remap Quote data based on filter
    valueTF (value,index) {
      if (this.valueTFObject[index]==null) {
        // Declare the row item
        this.valueTFObject[index] = [];
        this.$store.state.currencyList.forEach((declareCur) => {
          this.valueTFObject[index][declareCur] = [];
          this.timeFrameList.forEach((declareTF) => {
            this.valueTFObject[index][declareCur][declareTF] = [];
            this.contentType.forEach((declareContent) => {
              this.valueTFObject[index][declareCur][declareTF][declareContent] = [];
              this.displayGroup.forEach((declareDisplay) => {
                this.valueTFObject[index][declareCur][declareTF][declareContent][declareDisplay] = '';
              });
            });
          });
        });
      }
      // Fill valueTFObject based on selected timeframe 
      this.$store.state.timeframe.forEach((timeframeEach, indexTFEach) => {
          var averageTemp = 0;
          if (value.Currency==this.$store.state.currency && value.Years==timeframeEach && value.CouponType=='FIX') {
            this.displayGroup.forEach((displayName) => {
              this.valueTFObject[index][this.$store.state.currency][timeframeEach]['FIX'][displayName] = value[displayName];

            })

          };
          if (value.Currency==this.$store.state.currency && value.Years==timeframeEach && value.CouponType=='FRN') {
            this.displayGroup.forEach((displayName) => {
              this.valueTFObject[index][this.$store.state.currency][timeframeEach]['FRN'][displayName] = value[displayName];
            })
          };
      });
    },

    findMinimal(timeframe,number) {
      // Find minimal value of column, which defined in valueTF function
      if (this.minimalValue[timeframe]==number) {
        return "minimal-mark";
      }
    },

    theadSortBy (sortType) {
      Vue.set(this.sortBy,sortType,!this.sortBy[sortType]);
      if (sortType == 'dateSent') {
        Vue.set(this.sortBy,'dateSentGrey',false);
      } else {
        Vue.set(this.sortBy,'dateSentGrey',true);
      }
      Vue.set(this.sortBy,'param',sortType);
    },
  },
  computed: {
    averageCalc() {
      this.timeFrameList.forEach((time, timeInd) => {
        // Reset value
        this.averageFooter[time] = [];
        this.averageFooterFinal[time] = [];
        this.minimalValue[time] = 0;

        this.contentType.forEach((conType, conTypeInd) => {
          this.averageFooter[time][conType] = [];
          this.averageFooterFinal[time][conType] = [];

          this.valueTFObject.forEach((quote, quoteIndex) => {
            var valueDisplay = quote[this.$store.state.currency][time][conType][this.$store.state.display];
            if (valueDisplay!='' && valueDisplay!=null) {
              this.averageFooter[time][conType].push(valueDisplay);
              // Highlight low number only for FIX type
              if (conType=='FIX') {
                if (this.minimalValue[time]==0) {
                  this.minimalValue[time] = valueDisplay;
                  if (valueDisplay==0) {
                    this.minimalValue[time] = 0;
                  }
                } else if (valueDisplay < this.minimalValue[time]) {
                  this.minimalValue[time] = valueDisplay;
                }
              }
            }
          });
          const sum = arr => arr.reduce((a,c) => (a += c),0);
          const avg = arr => sum(arr) / arr.length;
          const dataGet = this.averageFooter[time][conType];
          var returnData = avg(dataGet.map(a =>  a));
          // returnData = returnData % 1 != 0 ? parseFloat(returnData).toFixed(2) :returnData;

          var returnDataGet = avg(dataGet.map(a =>  a));
          var returnData = 0;
          if (returnDataGet % 1 != 0 ) {
            returnData = parseFloat(returnDataGet.toFixed(2));
          } else {
            returnData = returnDataGet;
          }
          this.averageFooterFinal[time][conType] = isNaN(returnData) ? '':returnData;
        });
      });
    },

    filteredData() {
      // Empty Remap Quote
      this.valueTFObject = [];
      let tempData = JSON.parse(JSON.stringify(this.compData));
      let tempDataName = JSON.parse(JSON.stringify(this.compData));
      let searchQuery = this.$store.state.search;
      // Process search input
      if (searchQuery != '' && searchQuery) {
        tempData = tempData.filter((item) => {
          return item.Company
            .toUpperCase()
            .includes(searchQuery.toUpperCase())
        })
      }

      // Sort set default
      if (this.sortBy.param==null) {
        Vue.set(this.sortBy,'dateSent',true);
        Vue.set(this.sortBy,'param','dateSent');
      }

      // Sort by Date and Company
      if (this.sortBy.param=='dateSent' && this.sortBy.dateSent) {
        tempData = tempData.sort((a, b) => {
          if (b.Quote!=null) {
            var dateCompare = new Date(a.DateSent) > new Date(b.DateSent);
            var prefferedFlag = a.Preferred > b.Preferred;
            if (dateCompare && a.Quote!=null) {
              return -1;
            }
            if (prefferedFlag && a.Quote!=null) {
              return -1;
            }
          }
          return 0
        }); 
      } else if (this.sortBy.param=='dateSent' && !this.sortBy.dateSent) {
        tempData = tempData.sort((b, a) => {
          if (b.Quote!=null) {
            var dateCompare = new Date(a.DateSent) > new Date(b.DateSent);
            var prefferedFlag = a.Preferred < b.Preferred;
            if (dateCompare && a.Quote!=null) {
              return -1;
            }
            if (prefferedFlag && a.Quote!=null) {
              return -1;
            }
          }
          return 0
        }); 
      } else if (this.sortBy.param=='comp' && this.sortBy.comp) {

        tempData = tempData.sort((a, b) => {
          let fa = a.Company.toLowerCase(), fb = b.Company.toLowerCase();
          if (fa < fb  && a.Quote!=null) {
            return -1
          }
          if (fa > fb) {
            return 1 
          }
          return 0
        });
      } else if (this.sortBy.param=='comp' && !this.sortBy.comp) {
        tempData = tempData.sort((b, a) => {
          let fa = a.Company.toLowerCase(), fb = b.Company.toLowerCase();
          if (fa < fb  && b.Quote!=null) {
            return -1
          }
          if (fa > fb) {
            return 1 
          }
          return 0
        });
      }
      return tempData;
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      // Toggle the first row on document ready
      Vue.set(this.toggleShow,0,true);
    })
  }
}
